import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAdminEventImage, LazyBlurryBackground, LazyCurrencyInput, LazyCurrencyOutput, LazyDate, LazyEventArtImage, LazyEventCard, LazyEventDateAlternative, LazyEventDateRow, LazyEventImage, LazyEventPicker, LazyHighlights, LazyMeiliDate, LazyPagination, LazySearch, LazySimpleDate } from '#components'
const lazyGlobalComponents = [
  ["AdminEventImage", LazyAdminEventImage],
["BlurryBackground", LazyBlurryBackground],
["CurrencyInput", LazyCurrencyInput],
["CurrencyOutput", LazyCurrencyOutput],
["Date", LazyDate],
["EventArtImage", LazyEventArtImage],
["EventCard", LazyEventCard],
["EventDateAlternative", LazyEventDateAlternative],
["EventDateRow", LazyEventDateRow],
["EventImage", LazyEventImage],
["EventPicker", LazyEventPicker],
["Highlights", LazyHighlights],
["MeiliDate", LazyMeiliDate],
["Pagination", LazyPagination],
["Search", LazySearch],
["SimpleDate", LazySimpleDate],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

import { default as agbcNXYcBrPsUMeta } from "/opt/buildhome/repo/pages/agb.vue?macro=true";
import { default as abgeschlossenRITgVhTBALMeta } from "/opt/buildhome/repo/pages/anfragen/abgeschlossen.vue?macro=true";
import { default as indexF6Wl9YioPhMeta } from "/opt/buildhome/repo/pages/anfragen/index.vue?macro=true";
import { default as _91id_93wS9HGOtpCkMeta } from "/opt/buildhome/repo/pages/angebote/[id].vue?macro=true";
import { default as abgelehntzKYWhOdTFvMeta } from "/opt/buildhome/repo/pages/angebote/abgelehnt.vue?macro=true";
import { default as indexh321USYRZAMeta } from "/opt/buildhome/repo/pages/angebote/index.vue?macro=true";
import { default as indexioJ2g7ReQTMeta } from "/opt/buildhome/repo/pages/bestellungen/index.vue?macro=true";
import { default as _91id_93tdpgWNJyUYMeta } from "/opt/buildhome/repo/pages/booker/anfragen/[id].vue?macro=true";
import { default as indexi6guMPvsYpMeta } from "/opt/buildhome/repo/pages/booker/anfragen/index.vue?macro=true";
import { default as _91id_93s4I7jfSo3bMeta } from "/opt/buildhome/repo/pages/booker/bestellungen/[id].vue?macro=true";
import { default as indexs7GpnjqVLKMeta } from "/opt/buildhome/repo/pages/booker/bestellungen/index.vue?macro=true";
import { default as auswertung591mevEY0gMeta } from "/opt/buildhome/repo/pages/booker/gutscheine/auswertung.vue?macro=true";
import { default as indexhueKiE1ZOxMeta } from "/opt/buildhome/repo/pages/booker/gutscheine/index.vue?macro=true";
import { default as _91id_93d97aj6VB1IMeta } from "/opt/buildhome/repo/pages/booker/kunden/[id].vue?macro=true";
import { default as indexTkYm0iWuDmMeta } from "/opt/buildhome/repo/pages/booker/kunden/index.vue?macro=true";
import { default as newQ5IAEQpZaSMeta } from "/opt/buildhome/repo/pages/booker/kunden/new.vue?macro=true";
import { default as settingsflsRJFQ7EsMeta } from "/opt/buildhome/repo/pages/booker/settings.vue?macro=true";
import { default as indexLF2ffINXhuMeta } from "/opt/buildhome/repo/pages/booker/token/index.vue?macro=true";
import { default as indexqVDJglidTwMeta } from "/opt/buildhome/repo/pages/bookings/index.vue?macro=true";
import { default as datenschutzerklaerungmopvGp5MrkMeta } from "/opt/buildhome/repo/pages/datenschutzerklaerung.vue?macro=true";
import { default as _91token_93iAhwqoNCJRMeta } from "/opt/buildhome/repo/pages/einladung/[token].vue?macro=true";
import { default as indexHoVsR9pchZMeta } from "/opt/buildhome/repo/pages/einladung/index.vue?macro=true";
import { default as _91id_93e7uuupWfWMMeta } from "/opt/buildhome/repo/pages/eventkalender/[id].vue?macro=true";
import { default as indexKqTyzzSzbwMeta } from "/opt/buildhome/repo/pages/eventkalender/index.vue?macro=true";
import { default as sucheHRl6H9AnYbMeta } from "/opt/buildhome/repo/pages/eventkalender/suche.vue?macro=true";
import { default as impressumBXSuySyVnDMeta } from "/opt/buildhome/repo/pages/impressum.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as individuelldFlvOzsvawMeta } from "/opt/buildhome/repo/pages/individuell.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as passwort_45vergessenj4SqashpHlMeta } from "/opt/buildhome/repo/pages/passwort-vergessen.vue?macro=true";
import { default as profilnuXjapKIWCMeta } from "/opt/buildhome/repo/pages/profil.vue?macro=true";
import { default as datenschutzerklaerungKY9rx3DaACMeta } from "/opt/buildhome/repo/pages/public/datenschutzerklaerung.vue?macro=true";
import { default as resetAPQn3WObAwMeta } from "/opt/buildhome/repo/pages/reset.vue?macro=true";
import { default as indexDXVvG7ZXwvMeta } from "/opt/buildhome/repo/pages/token/index.vue?macro=true";
import { default as vr_45loginFKSacpL5wrMeta } from "/opt/buildhome/repo/pages/vr-login.vue?macro=true";
export default [
  {
    name: agbcNXYcBrPsUMeta?.name ?? "agb",
    path: agbcNXYcBrPsUMeta?.path ?? "/agb",
    meta: agbcNXYcBrPsUMeta || {},
    alias: agbcNXYcBrPsUMeta?.alias || [],
    redirect: agbcNXYcBrPsUMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: abgeschlossenRITgVhTBALMeta?.name ?? "anfragen-abgeschlossen",
    path: abgeschlossenRITgVhTBALMeta?.path ?? "/anfragen/abgeschlossen",
    meta: abgeschlossenRITgVhTBALMeta || {},
    alias: abgeschlossenRITgVhTBALMeta?.alias || [],
    redirect: abgeschlossenRITgVhTBALMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/anfragen/abgeschlossen.vue").then(m => m.default || m)
  },
  {
    name: indexF6Wl9YioPhMeta?.name ?? "anfragen",
    path: indexF6Wl9YioPhMeta?.path ?? "/anfragen",
    meta: indexF6Wl9YioPhMeta || {},
    alias: indexF6Wl9YioPhMeta?.alias || [],
    redirect: indexF6Wl9YioPhMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/anfragen/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wS9HGOtpCkMeta?.name ?? "angebote-id",
    path: _91id_93wS9HGOtpCkMeta?.path ?? "/angebote/:id()",
    meta: _91id_93wS9HGOtpCkMeta || {},
    alias: _91id_93wS9HGOtpCkMeta?.alias || [],
    redirect: _91id_93wS9HGOtpCkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/angebote/[id].vue").then(m => m.default || m)
  },
  {
    name: abgelehntzKYWhOdTFvMeta?.name ?? "angebote-abgelehnt",
    path: abgelehntzKYWhOdTFvMeta?.path ?? "/angebote/abgelehnt",
    meta: abgelehntzKYWhOdTFvMeta || {},
    alias: abgelehntzKYWhOdTFvMeta?.alias || [],
    redirect: abgelehntzKYWhOdTFvMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/angebote/abgelehnt.vue").then(m => m.default || m)
  },
  {
    name: indexh321USYRZAMeta?.name ?? "angebote",
    path: indexh321USYRZAMeta?.path ?? "/angebote",
    meta: indexh321USYRZAMeta || {},
    alias: indexh321USYRZAMeta?.alias || [],
    redirect: indexh321USYRZAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/angebote/index.vue").then(m => m.default || m)
  },
  {
    name: indexioJ2g7ReQTMeta?.name ?? "bestellungen",
    path: indexioJ2g7ReQTMeta?.path ?? "/bestellungen",
    meta: indexioJ2g7ReQTMeta || {},
    alias: indexioJ2g7ReQTMeta?.alias || [],
    redirect: indexioJ2g7ReQTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/bestellungen/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tdpgWNJyUYMeta?.name ?? "booker-anfragen-id",
    path: _91id_93tdpgWNJyUYMeta?.path ?? "/booker/anfragen/:id()",
    meta: _91id_93tdpgWNJyUYMeta || {},
    alias: _91id_93tdpgWNJyUYMeta?.alias || [],
    redirect: _91id_93tdpgWNJyUYMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/anfragen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi6guMPvsYpMeta?.name ?? "booker-anfragen",
    path: indexi6guMPvsYpMeta?.path ?? "/booker/anfragen",
    meta: indexi6guMPvsYpMeta || {},
    alias: indexi6guMPvsYpMeta?.alias || [],
    redirect: indexi6guMPvsYpMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/anfragen/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93s4I7jfSo3bMeta?.name ?? "booker-bestellungen-id",
    path: _91id_93s4I7jfSo3bMeta?.path ?? "/booker/bestellungen/:id()",
    meta: _91id_93s4I7jfSo3bMeta || {},
    alias: _91id_93s4I7jfSo3bMeta?.alias || [],
    redirect: _91id_93s4I7jfSo3bMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/bestellungen/[id].vue").then(m => m.default || m)
  },
  {
    name: indexs7GpnjqVLKMeta?.name ?? "booker-bestellungen",
    path: indexs7GpnjqVLKMeta?.path ?? "/booker/bestellungen",
    meta: indexs7GpnjqVLKMeta || {},
    alias: indexs7GpnjqVLKMeta?.alias || [],
    redirect: indexs7GpnjqVLKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/bestellungen/index.vue").then(m => m.default || m)
  },
  {
    name: auswertung591mevEY0gMeta?.name ?? "booker-gutscheine-auswertung",
    path: auswertung591mevEY0gMeta?.path ?? "/booker/gutscheine/auswertung",
    meta: auswertung591mevEY0gMeta || {},
    alias: auswertung591mevEY0gMeta?.alias || [],
    redirect: auswertung591mevEY0gMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/gutscheine/auswertung.vue").then(m => m.default || m)
  },
  {
    name: indexhueKiE1ZOxMeta?.name ?? "booker-gutscheine",
    path: indexhueKiE1ZOxMeta?.path ?? "/booker/gutscheine",
    meta: indexhueKiE1ZOxMeta || {},
    alias: indexhueKiE1ZOxMeta?.alias || [],
    redirect: indexhueKiE1ZOxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/gutscheine/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d97aj6VB1IMeta?.name ?? "booker-kunden-id",
    path: _91id_93d97aj6VB1IMeta?.path ?? "/booker/kunden/:id()",
    meta: _91id_93d97aj6VB1IMeta || {},
    alias: _91id_93d97aj6VB1IMeta?.alias || [],
    redirect: _91id_93d97aj6VB1IMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/kunden/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTkYm0iWuDmMeta?.name ?? "booker-kunden",
    path: indexTkYm0iWuDmMeta?.path ?? "/booker/kunden",
    meta: indexTkYm0iWuDmMeta || {},
    alias: indexTkYm0iWuDmMeta?.alias || [],
    redirect: indexTkYm0iWuDmMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/kunden/index.vue").then(m => m.default || m)
  },
  {
    name: newQ5IAEQpZaSMeta?.name ?? "booker-kunden-new",
    path: newQ5IAEQpZaSMeta?.path ?? "/booker/kunden/new",
    meta: newQ5IAEQpZaSMeta || {},
    alias: newQ5IAEQpZaSMeta?.alias || [],
    redirect: newQ5IAEQpZaSMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/kunden/new.vue").then(m => m.default || m)
  },
  {
    name: settingsflsRJFQ7EsMeta?.name ?? "booker-settings",
    path: settingsflsRJFQ7EsMeta?.path ?? "/booker/settings",
    meta: settingsflsRJFQ7EsMeta || {},
    alias: settingsflsRJFQ7EsMeta?.alias || [],
    redirect: settingsflsRJFQ7EsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/settings.vue").then(m => m.default || m)
  },
  {
    name: indexLF2ffINXhuMeta?.name ?? "booker-token",
    path: indexLF2ffINXhuMeta?.path ?? "/booker/token",
    meta: indexLF2ffINXhuMeta || {},
    alias: indexLF2ffINXhuMeta?.alias || [],
    redirect: indexLF2ffINXhuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/booker/token/index.vue").then(m => m.default || m)
  },
  {
    name: indexqVDJglidTwMeta?.name ?? "bookings",
    path: indexqVDJglidTwMeta?.path ?? "/bookings",
    meta: indexqVDJglidTwMeta || {},
    alias: indexqVDJglidTwMeta?.alias || [],
    redirect: indexqVDJglidTwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: datenschutzerklaerungmopvGp5MrkMeta?.name ?? "datenschutzerklaerung",
    path: datenschutzerklaerungmopvGp5MrkMeta?.path ?? "/datenschutzerklaerung",
    meta: datenschutzerklaerungmopvGp5MrkMeta || {},
    alias: datenschutzerklaerungmopvGp5MrkMeta?.alias || [],
    redirect: datenschutzerklaerungmopvGp5MrkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/datenschutzerklaerung.vue").then(m => m.default || m)
  },
  {
    name: _91token_93iAhwqoNCJRMeta?.name ?? "einladung-token",
    path: _91token_93iAhwqoNCJRMeta?.path ?? "/einladung/:token()",
    meta: _91token_93iAhwqoNCJRMeta || {},
    alias: _91token_93iAhwqoNCJRMeta?.alias || [],
    redirect: _91token_93iAhwqoNCJRMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/einladung/[token].vue").then(m => m.default || m)
  },
  {
    name: indexHoVsR9pchZMeta?.name ?? "einladung",
    path: indexHoVsR9pchZMeta?.path ?? "/einladung",
    meta: indexHoVsR9pchZMeta || {},
    alias: indexHoVsR9pchZMeta?.alias || [],
    redirect: indexHoVsR9pchZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/einladung/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93e7uuupWfWMMeta?.name ?? "eventkalender-id",
    path: _91id_93e7uuupWfWMMeta?.path ?? "/eventkalender/:id()",
    meta: _91id_93e7uuupWfWMMeta || {},
    alias: _91id_93e7uuupWfWMMeta?.alias || [],
    redirect: _91id_93e7uuupWfWMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/eventkalender/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKqTyzzSzbwMeta?.name ?? "eventkalender",
    path: indexKqTyzzSzbwMeta?.path ?? "/eventkalender",
    meta: indexKqTyzzSzbwMeta || {},
    alias: indexKqTyzzSzbwMeta?.alias || [],
    redirect: indexKqTyzzSzbwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/eventkalender/index.vue").then(m => m.default || m)
  },
  {
    name: sucheHRl6H9AnYbMeta?.name ?? "eventkalender-suche",
    path: sucheHRl6H9AnYbMeta?.path ?? "/eventkalender/suche",
    meta: sucheHRl6H9AnYbMeta || {},
    alias: sucheHRl6H9AnYbMeta?.alias || [],
    redirect: sucheHRl6H9AnYbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/eventkalender/suche.vue").then(m => m.default || m)
  },
  {
    name: impressumBXSuySyVnDMeta?.name ?? "impressum",
    path: impressumBXSuySyVnDMeta?.path ?? "/impressum",
    meta: impressumBXSuySyVnDMeta || {},
    alias: impressumBXSuySyVnDMeta?.alias || [],
    redirect: impressumBXSuySyVnDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: individuelldFlvOzsvawMeta?.name ?? "individuell",
    path: individuelldFlvOzsvawMeta?.path ?? "/individuell",
    meta: individuelldFlvOzsvawMeta || {},
    alias: individuelldFlvOzsvawMeta?.alias || [],
    redirect: individuelldFlvOzsvawMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/individuell.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: passwort_45vergessenj4SqashpHlMeta?.name ?? "passwort-vergessen",
    path: passwort_45vergessenj4SqashpHlMeta?.path ?? "/passwort-vergessen",
    meta: passwort_45vergessenj4SqashpHlMeta || {},
    alias: passwort_45vergessenj4SqashpHlMeta?.alias || [],
    redirect: passwort_45vergessenj4SqashpHlMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/passwort-vergessen.vue").then(m => m.default || m)
  },
  {
    name: profilnuXjapKIWCMeta?.name ?? "profil",
    path: profilnuXjapKIWCMeta?.path ?? "/profil",
    meta: profilnuXjapKIWCMeta || {},
    alias: profilnuXjapKIWCMeta?.alias || [],
    redirect: profilnuXjapKIWCMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/profil.vue").then(m => m.default || m)
  },
  {
    name: datenschutzerklaerungKY9rx3DaACMeta?.name ?? "public-datenschutzerklaerung",
    path: datenschutzerklaerungKY9rx3DaACMeta?.path ?? "/public/datenschutzerklaerung",
    meta: datenschutzerklaerungKY9rx3DaACMeta || {},
    alias: datenschutzerklaerungKY9rx3DaACMeta?.alias || [],
    redirect: datenschutzerklaerungKY9rx3DaACMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/public/datenschutzerklaerung.vue").then(m => m.default || m)
  },
  {
    name: resetAPQn3WObAwMeta?.name ?? "reset",
    path: resetAPQn3WObAwMeta?.path ?? "/reset",
    meta: resetAPQn3WObAwMeta || {},
    alias: resetAPQn3WObAwMeta?.alias || [],
    redirect: resetAPQn3WObAwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: indexDXVvG7ZXwvMeta?.name ?? "token",
    path: indexDXVvG7ZXwvMeta?.path ?? "/token",
    meta: indexDXVvG7ZXwvMeta || {},
    alias: indexDXVvG7ZXwvMeta?.alias || [],
    redirect: indexDXVvG7ZXwvMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/token/index.vue").then(m => m.default || m)
  },
  {
    name: vr_45loginFKSacpL5wrMeta?.name ?? "vr-login",
    path: vr_45loginFKSacpL5wrMeta?.path ?? "/vr-login",
    meta: vr_45loginFKSacpL5wrMeta || {},
    alias: vr_45loginFKSacpL5wrMeta?.alias || [],
    redirect: vr_45loginFKSacpL5wrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/vr-login.vue").then(m => m.default || m)
  }
]
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/opt/buildhome/repo/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import awn_client_wYS8cgGq3G from "/opt/buildhome/repo/plugins/awn.client.js";
import chartjs_client_22DCMFgtqN from "/opt/buildhome/repo/plugins/chartjs.client.js";
import color_picker_client_sOHgVq3Soe from "/opt/buildhome/repo/plugins/color-picker.client.js";
import flare_client_TQnyawnbiN from "/opt/buildhome/repo/plugins/flare.client.js";
import tailwind_datepicker_client_wmvfXAN8Jr from "/opt/buildhome/repo/plugins/tailwind-datepicker.client.js";
import veevalidate_components_ipnjB2Q1ls from "/opt/buildhome/repo/plugins/veevalidate-components.js";
import veevalidate_rules_e9Gp0lCERk from "/opt/buildhome/repo/plugins/veevalidate-rules.js";
import vue_number_animation_client_SVRMrBHwtm from "/opt/buildhome/repo/plugins/vue-number-animation.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  awn_client_wYS8cgGq3G,
  chartjs_client_22DCMFgtqN,
  color_picker_client_sOHgVq3Soe,
  flare_client_TQnyawnbiN,
  tailwind_datepicker_client_wmvfXAN8Jr,
  veevalidate_components_ipnjB2Q1ls,
  veevalidate_rules_e9Gp0lCERk,
  vue_number_animation_client_SVRMrBHwtm
]
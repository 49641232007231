import { defineNuxtPlugin } from "nuxt/app";
import "awesome-notifications/dist/style.css";
import AWN from "awesome-notifications";

export default defineNuxtPlugin((nuxtApp) => {
  const vuwAWNOptions = {
    position: "bottom-right",
    labels: {
      success: "Erfolg",
      alert: "Achtung",
    },
  };

  return {
    provide: {
      awn: new AWN(vuwAWNOptions),
    },
  };
});

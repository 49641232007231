<template>
  <input
    ref="inputRef"
    type="text"
    class="block w-full rounded-[--md-round] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
      currency: "EUR",
      precision: 2,
      valueScaling: "precision",
    });

    return { inputRef };
  },
};
</script>
